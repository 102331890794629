body {
  outline: solid 1px gold;
  margin: 0;
  padding: 0;
  font-family: '微軟正黑體', sans-serif;
}

html {
  background: white;
  scroll-behavior: smooth;
}